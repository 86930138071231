import { environment } from './../../environments/environment';
import { format, addYears } from 'date-fns';
import { Product } from '../../app/model/product';
import { getContractNumber } from '../../app/services/sci-rate.service';
import { isEmpty, isNil } from 'lodash';

export interface ReviewPageInfo {
    applicationConfirmation: boolean;
    fraudDisclosureConfirmation: boolean;
    edeliveryConsentInitials: string;
    customerFirstName: string;
    customerLastName: string;
    customerPhoneNumber: string;
    customerEmail: string;
}

export interface AgentInfo {
    agencyName: string;
    agentProducerCode: string;
    agentLegacyCode: string;
    agentFirstName: string;
    agentLastName: string;
    agentPhoneNumber: string;
    agentEmail: string;
}

export class EmailJourney {
    public emailJourneyPayload: any[] = [
        {
            data: [
                {
                    keys: {},
                    values: {
                        PrimaryKey: 'qid_primaryKey',
                        ContactName: 'qid_contactName',
                        ContactEmail: 'qid_email',
                        BusinessName: 'qid_businessName',
                        EffectiveDate: 'qid_effectiveDate',
                        ExpirationDate: 'qid_expirationDate',
                        DateAdded: 'qid_currentDate',
                        PartnerId: 'qid_partnerId',
                        producerCode: 'qid_producerCode',
                        partnerCode: 'qid_partnerCode',
                        partnerSource: 'qid_partnerSource',
                        partnerName: 'qid_partnerName',
                        TermDate: '',
                        primaryLocation: 'qid_address',
                        Quote1: 'qid_quote1',
                        Quote2: 'qid_quote2',
                        Quote3: 'qid_quote3',
                        Quote4: 'qid_quote4',
                        Quote5: 'qid_quote5',
                        quotePattern1: 'qid_quotePattern1',
                        quotePattern2: 'qid_quotePattern2',
                        quotePattern3: 'qid_quotePattern3',
                        quotePattern4: 'qid_quotePattern4',
                        quotePattern5: 'qid_quotePattern5',
                        quoteType1: 'qid_quoteType1',
                        quoteType2: 'qid_quoteType2',
                        quoteType3: 'qid_quoteType3',
                        quoteType4: 'qid_quoteType4',
                        quoteType5: 'qid_quoteType5',
                        Product1: 'qid_product1',
                        Product2: 'qid_product2',
                        Product3: 'qid_product3',
                        Product4: 'qid_product4',
                        Product5: 'qid_product5',
                        mpUrl1: 'qid_mpUrl1',
                        mpUrl2: 'qid_mpUrl2',
                        mpUrl3: 'qid_mpUrl3',
                        mpUrl4: 'qid_mpUrl4',
                        mpUrl5: 'qid_mpUrl5',
                        quoteProposalId1: 'qid_quoteProposalId1',
                        quoteProposalId2: 'qid_quoteProposalId2',
                        quoteProposalId3: 'qid_quoteProposalId3',
                        quoteProposalId4: 'qid_quoteProposalId4',
                        quoteProposalId5: 'qid_quoteProposalId5',
                        quoteStatus1: 'qid_quoteStatus1',
                        quoteStatus2: 'qid_quoteStatus2',
                        quoteStatus3: 'qid_quoteStatus3',
                        quoteStatus4: 'qid_quoteStatus4',
                        quoteStatus5: 'qid_quoteStatus5',
                        isQuoteReferred1: 'qid_isQuoteReferred1',
                        isQuoteReferred2: 'qid_isQuoteReferred2',
                        isQuoteReferred3: 'qid_isQuoteReferred3',
                        isQuoteReferred4: 'qid_isQuoteReferred4',
                        isQuoteReferred5: 'qid_isQuoteReferred5',
                        PurchasedFlagProduct1: 'qid_flag1',
                        PurchasedFlagProduct2: 'qid_flag2',
                        PurchasedFlagProduct3: 'qid_flag3',
                        PurchasedFlagProduct4: 'qid_flag4',
                        PurchasedFlagProduct5: 'qid_flag5',
                        WebpageURLProduct1:
                            'qid_baseUrl/retrieve-quote?quote=qid_quote1',
                        WebpageURLProduct2:
                            'qid_baseUrl/retrieve-quote?quote=qid_quote2',
                        WebpageURLProduct3:
                            'qid_baseUrl/retrieve-quote?quote=qid_quote3',
                        WebpageURLProduct4:
                            'qid_baseUrl/retrieve-quote?quote=qid_quote4',
                        WebpageURLProduct5:
                            'qid_baseUrl/retrieve-quote?quote=qid_quote5',
                        YearlyPremiumProduct1: 'qid_yr1',
                        MonthlyPremiumProduct1: 'qid_mn1',
                        YearlyPremiumProduct2: 'qid_yr2',
                        MonthlyPremiumProduct2: 'qid_mn2',
                        YearlyPremiumProduct3: 'qid_yr3',
                        MonthlyPremiumProduct3: 'qid_mn3',
                        YearlyPremiumProduct4: 'qid_yr4',
                        MonthlyPremiumProduct4: 'qid_mn4',
                        YearlyPremiumProduct5: 'qid_yr5',
                        MonthlyPremiumProduct5: 'qid_mn5',
                    },
                },
            ],
            productSourceSystems: {
                ProductSourceSystem1: 'qid_prodSource1',
                ProductSourceSystem2: 'qid_prodSource2',
                ProductSourceSystem3: 'qid_prodSource3',
                ProductSourceSystem4: 'qid_prodSource4',
                ProductSourceSystem5: 'qid_prodSource5',
            },
            businessInformation: {
                haveInsurance: 'qid_insurance',
                revenue: 'qid_revenue',
                zipcode: 'qid_zipcode',
                businessType: 'qid_businessType',
                digitalCode: 'qid_digitalCode',
                wsgClassid: 'qid_wsgClassid',
            },
            reviewPageInfo: {
                applicationConfirmation: 'qid_confirmation',
                fraudDisclosureConfirmation: 'qid_disclosureAccepted',
                edeliveryConsentInitials: 'qid_initials',
                customerFirstName: 'qid_firstName',
                customerLastName: 'qid_lastName',
                customerPhoneNumber: 'qid_telNumber',
                customerEmail: 'qid_cusEmail',
            },
            agentInfo: {
                agencyName: 'qid_agencyName',
                agentProducerCode: 'qid_agentProducerCode',
                agentLegacyCode: 'qid_agentLegacyCode',
                agentFirstName: 'qid_agentFirstName',
                agentLastName: 'qid_agentLastName',
                agentPhoneNumber: 'qid_agentTelNumber',
                agentEmail: 'qid_agentEmail',
            },
            startEmailJourney: 'qid_journeyStatus',
        },
    ];

    /** */

    public captureEmailPayload: any[] = [
        {
            data: [
                {
                    keys: {},
                    values: {
                        ContactEmail: 'qid_email',
                        PartnerId: 'qid_partnerId',
                    },
                },
            ],
            productSourceSystems: {
                ProductSourceSystem1: 'NA',
            },
            startEmailJourney: false,
        },
    ];

    public captureSaveQuotePayload: any[] = [
        {
            data: [
                {
                    keys: {},
                    values: {
                        ContactEmail: 'qid_email',
                        PartnerId: 'qid_partnerId',
                        producerCode: 'qid_producerCode',
                        partnerCode: 'qid_partnerCode',
                        partnerSource: 'qid_partnerSource',
                        partnerName: 'qid_partnerName',
                    },
                },
            ],
            productSourceSystems: {
                ProductSourceSystem1: 'NA',
            },
            businessInformation: {
                haveInsurance: false,
                revenue: 'qid_revenue',
                zipcode: 'qid_zipcode',
                businessType: 'qid_businessType',
                digitalCode: 'qid_digitalCode',
                wsgClassid: 'qid_wsgClassid',
            },
            startEmailJourney: false,
        },
    ];

    // Build email Journey Payload
    public buildEmailJourneyQuoteRequest(
        emailJourneyPayload: any,
        simpleQuestionsObj: any,
        products: any,
        emailId: any,
        journeyStatus: boolean,
        partnerId: any,
        businessInfo: any,
        partnerInfo: any,
        reviewPageInfo: ReviewPageInfo = null,
        agentInfo: AgentInfo = null
    ) {
        let state: string;
        if (simpleQuestionsObj && simpleQuestionsObj.length > 0) {
            simpleQuestionsObj.forEach((questionGroup) => {
                if (
                    questionGroup.group_name === 'Business Address' &&
                    questionGroup.questions &&
                    questionGroup.questions.length > 0
                ) {
                    questionGroup.questions.forEach((question) => {
                        if (
                            question.question_id === '6' &&
                            !isNil(question.value) &&
                            !isEmpty(question.value)
                        ) {
                            state = question.value;
                        }
                    });
                }
            });
        }
        if (
            typeof emailJourneyPayload !== 'object' ||
            emailJourneyPayload === undefined ||
            products.length === 0
        ) {
            return;
        } else {
            for (const item in emailJourneyPayload) {
                if (
                    typeof emailJourneyPayload[item] === 'string' &&
                    emailJourneyPayload[item].includes('qid')
                ) {
                    if (
                        typeof simpleQuestionsObj[emailJourneyPayload[item]] !==
                        'undefined'
                    ) {
                        emailJourneyPayload[item] =
                            simpleQuestionsObj[emailJourneyPayload[item]]
                                .value || '';
                    } else {
                        // Get the business name from the user answers
                        let businessName: any;
                        let effectiveDate: any;
                        let primaryLoc: string;
                        simpleQuestionsObj.forEach((questionGroup) => {
                            if (
                                questionGroup.group_name ===
                                'Universal Questions'
                            ) {
                                questionGroup.questions.forEach((question) => {
                                    if (question.question_id === '1') {
                                        // Company name
                                        businessName = question.value;
                                    }
                                });
                            } else if (
                                questionGroup.group_name ===
                                'Coverage Start Date'
                            ) {
                                questionGroup.questions.forEach((question) => {
                                    effectiveDate = question.value;
                                });
                            } else if (
                                questionGroup.group_name === 'Business Address'
                            ) {
                                questionGroup.questions.forEach((que) => {
                                    if (
                                        que.question_id === '4' &&
                                        que.rawValue
                                    ) {
                                        primaryLoc =
                                            que.rawValue.FormattedString;
                                    }
                                });
                            }
                        });

                        // data from user answers
                        switch (emailJourneyPayload[item]) {
                            case 'qid_currentDate':
                                emailJourneyPayload[item] = format(
                                    new Date(),
                                    'MM/dd/yyyy'
                                );
                                break;
                            case 'qid_contactName':
                                emailJourneyPayload[item] = businessName;
                                break;
                            case 'qid_businessName':
                                emailJourneyPayload[item] = businessName;
                                break;
                            case 'qid_effectiveDate':
                                emailJourneyPayload[item] = effectiveDate;
                                break;
                            case 'qid_address':
                                emailJourneyPayload[item] = primaryLoc;
                                break;
                            case 'qid_expirationDate':
                                emailJourneyPayload[item] = format(
                                    addYears(Date.parse(effectiveDate), 1),
                                    'MM/dd/yyyy'
                                );
                                break;
                            case 'qid_partnerId':
                                emailJourneyPayload[item] = partnerId;
                                break;
                            case 'qid_producerCode':
                                emailJourneyPayload[item] = getContractNumber(
                                    partnerId,
                                    state
                                );
                                break;
                            case 'qid_partnerCode':
                                emailJourneyPayload[item] =
                                    !isNil(partnerInfo) &&
                                    !isEmpty(partnerInfo) &&
                                    partnerInfo?.partnercode
                                        ? partnerInfo.partnercode
                                        : '';
                                break;
                            case 'qid_partnerSource':
                                emailJourneyPayload[item] =
                                    !isNil(partnerInfo) &&
                                    !isEmpty(partnerInfo) &&
                                    partnerInfo?.utm_source
                                        ? partnerInfo.utm_source
                                        : '';
                                break;
                            case 'qid_partnerName':
                                emailJourneyPayload[item] =
                                    !isNil(partnerInfo) &&
                                    !isEmpty(partnerInfo) &&
                                    partnerInfo?.utm_source
                                        ? partnerInfo.utm_source
                                        : '';
                                break;

                            case 'qid_email':
                                emailJourneyPayload[item] = emailId;
                                break;
                            case 'qid_primaryKey':
                                emailJourneyPayload[item] =
                                    emailId !== ''
                                        ? emailId +
                                          format(new Date(), 'yyyy-MM-dd')
                                        : '';
                                break;
                            case 'qid_journeyStatus':
                                emailJourneyPayload[item] = journeyStatus;
                                break;
                            case 'qid_insurance':
                                emailJourneyPayload[item] =
                                    businessInfo.insurance &&
                                    businessInfo.insurance.value &&
                                    businessInfo.insurance.value
                                        .toString()
                                        .toLowerCase() === 'yes'
                                        ? true
                                        : false;
                                break;
                            case 'qid_revenue':
                                emailJourneyPayload[item] =
                                    businessInfo.revenue &&
                                    businessInfo.revenue.value
                                        ? businessInfo.revenue.value
                                        : '';
                                break;
                            case 'qid_zipcode':
                                emailJourneyPayload[item] =
                                    businessInfo.zipcode &&
                                    businessInfo.zipcode.value &&
                                    businessInfo.zipcode.value.length
                                        ? businessInfo.zipcode.value[0]
                                              .PostalCode
                                        : '';
                                break;
                            case 'qid_businessType':
                                emailJourneyPayload[item] =
                                    businessInfo.business &&
                                    businessInfo.business.value
                                        ? businessInfo.business.value.name
                                        : '';
                                break;
                            case 'qid_digitalCode':
                                emailJourneyPayload[item] =
                                    businessInfo.business &&
                                    businessInfo.business.value
                                        ? businessInfo.business.value
                                              .digitalClassCode
                                        : '';
                                break;
                            case 'qid_wsgClassid':
                                emailJourneyPayload[item] =
                                    businessInfo.business &&
                                    businessInfo.business.value
                                        ? businessInfo.business.value.wsgClassId
                                        : '';
                                break;

                            case 'qid_confirmation':
                                emailJourneyPayload[item] =
                                    reviewPageInfo &&
                                    reviewPageInfo.applicationConfirmation
                                        ? reviewPageInfo.applicationConfirmation
                                        : false;
                                break;

                            case 'qid_disclosureAccepted':
                                emailJourneyPayload[item] =
                                    reviewPageInfo &&
                                    reviewPageInfo.fraudDisclosureConfirmation
                                        ? reviewPageInfo.fraudDisclosureConfirmation
                                        : false;
                                break;

                            case 'qid_initials':
                                emailJourneyPayload[item] =
                                    reviewPageInfo &&
                                    reviewPageInfo.edeliveryConsentInitials
                                        ? reviewPageInfo.edeliveryConsentInitials
                                        : '';
                                break;

                            case 'qid_firstName':
                                emailJourneyPayload[item] =
                                    reviewPageInfo &&
                                    reviewPageInfo.customerFirstName
                                        ? reviewPageInfo.customerFirstName
                                        : '';
                                break;

                            case 'qid_lastName':
                                emailJourneyPayload[item] =
                                    reviewPageInfo &&
                                    reviewPageInfo.customerLastName
                                        ? reviewPageInfo.customerLastName
                                        : '';
                                break;

                            case 'qid_telNumber':
                                emailJourneyPayload[item] =
                                    reviewPageInfo &&
                                    reviewPageInfo.customerPhoneNumber
                                        ? reviewPageInfo.customerPhoneNumber
                                        : '';
                                break;
                            case 'qid_cusEmail':
                                emailJourneyPayload[item] =
                                    reviewPageInfo &&
                                    reviewPageInfo.customerEmail
                                        ? reviewPageInfo.customerEmail
                                        : '';
                                break;

                            case 'qid_agencyName':
                                emailJourneyPayload[item] =
                                    agentInfo && agentInfo.agencyName
                                        ? agentInfo.agencyName
                                        : '';
                                break;

                            case 'qid_agentProducerCode':
                                emailJourneyPayload[item] =
                                    agentInfo && agentInfo.agentProducerCode
                                        ? agentInfo.agentProducerCode
                                        : '';
                                break;

                            case 'qid_agentLegacyCode':
                                emailJourneyPayload[item] =
                                    agentInfo && agentInfo.agentLegacyCode
                                        ? agentInfo.agentLegacyCode
                                        : '';
                                break;

                            case 'qid_agentFirstName':
                                emailJourneyPayload[item] =
                                    agentInfo && agentInfo.agentFirstName
                                        ? agentInfo.agentFirstName
                                        : '';
                                break;

                            case 'qid_agentLastName':
                                emailJourneyPayload[item] =
                                    agentInfo && agentInfo.agentLastName
                                        ? agentInfo.agentLastName
                                        : '';
                                break;

                            case 'qid_agentTelNumber':
                                emailJourneyPayload[item] =
                                    agentInfo && agentInfo.agentPhoneNumber
                                        ? agentInfo.agentPhoneNumber
                                        : '';
                                break;

                            case 'qid_agentEmail':
                                emailJourneyPayload[item] =
                                    agentInfo && agentInfo.agentEmail
                                        ? agentInfo.agentEmail
                                        : '';
                                break;
                        }

                        // data from products selected
                        products.forEach((product: Product) => {
                            // Only send products that have been added to the cart
                            if (
                                product.selected ||
                                (product.productSourceSystem === 'SCI' &&
                                    parseInt(
                                        product.premium_amount.Premium,
                                        10
                                    ) > 0 &&
                                    product.productId !== 'UMBRELLA')
                            ) {
                                const seqOfProduct =
                                    products.indexOf(product) + 1;
                                switch (emailJourneyPayload[item]) {
                                    case 'qid_quote' + seqOfProduct: // quote numbers
                                        if (
                                            product.quote_number &&
                                            !isNil(product.quote_number) &&
                                            !isEmpty(product.quote_number)
                                        ) {
                                            emailJourneyPayload[item] =
                                                product.quote_number;
                                        }
                                        break;
                                    case 'qid_product' + seqOfProduct: // product name
                                        if (
                                            product.quote_number &&
                                            !isNil(product.quote_number) &&
                                            !isEmpty(product.quote_number)
                                        ) {
                                            emailJourneyPayload[item] =
                                                product.name;
                                        }
                                        break;
                                    case 'qid_quotePattern' + seqOfProduct: // quote pattern
                                        if (
                                            product.quote_number &&
                                            product.quotePattern &&
                                            !isNil(product.quotePattern) &&
                                            !isEmpty(product.quotePattern)
                                        ) {
                                            emailJourneyPayload[item] =
                                                product.quotePattern;
                                        }
                                        break;
                                    case 'qid_quoteType' + seqOfProduct: // quote type
                                        if (
                                            product.quote_number &&
                                            product.quoteType &&
                                            !isNil(product.quoteType) &&
                                            !isEmpty(product.quoteType)
                                        ) {
                                            emailJourneyPayload[item] =
                                                product.quoteType;
                                        }
                                        break;
                                    case 'qid_mpUrl' + seqOfProduct: // Product web URL'S
                                        if (
                                            product.quote_number &&
                                            product.mpWebUrl &&
                                            !isNil(product.mpWebUrl) &&
                                            !isEmpty(product.mpWebUrl)
                                        ) {
                                            emailJourneyPayload[item] =
                                                product.mpWebUrl;
                                        }
                                        break;
                                    case 'qid_quoteProposalId' + seqOfProduct: // Product quote proposal ID
                                        if (
                                            product.quote_number &&
                                            product.quoteProposalId &&
                                            !isNil(product.quoteProposalId) &&
                                            !isEmpty(product.quoteProposalId)
                                        ) {
                                            emailJourneyPayload[item] =
                                                product.quoteProposalId;
                                        }
                                        break;

                                    case 'qid_quoteStatus' + seqOfProduct: // Product quote status
                                        if (
                                            product.quote_number &&
                                            product.quoteStatus &&
                                            !isNil(product.quoteStatus) &&
                                            !isEmpty(product.quoteStatus)
                                        ) {
                                            emailJourneyPayload[item] =
                                                product.quoteStatus;
                                        }
                                        break;

                                    case 'qid_isQuoteReferred' + seqOfProduct: // Product quote referred flag
                                        if (product.quote_number) {
                                            emailJourneyPayload[
                                                item
                                            ] = product.isQuoteReferred
                                                ? product.isQuoteReferred
                                                : false;
                                        }
                                        break;

                                    case 'qid_flag' + seqOfProduct:
                                        emailJourneyPayload[item] =
                                            product.quote_number &&
                                            product.policyIssuedData &&
                                            product.policyIssuedData
                                                .PolicyResponse &&
                                            product.policyIssuedData
                                                .PolicyResponse.PolicyNumber
                                                ? 'true'
                                                : 'false';
                                        break;
                                    case 'qid_yr' + seqOfProduct: // yearly premium value
                                        if (
                                            product.quote_number &&
                                            product.errorMessage != null
                                        ) {
                                            if (
                                                product.productSourceSystem !==
                                                    'SCI' &&
                                                (product.errorMessage !==
                                                    'Referral' ||
                                                    product.errorMessage !==
                                                        'Declined' ||
                                                    product.errorMessage !==
                                                        'Decline')
                                            ) {
                                                if (product.selectedpackage) {
                                                    emailJourneyPayload[
                                                        item
                                                    ] = Number.parseFloat(
                                                        product.selectedpackage
                                                            .premiumAmount
                                                    ).toFixed(2);
                                                } else {
                                                    emailJourneyPayload[
                                                        item
                                                    ] = Number.parseFloat(
                                                        product
                                                            .productPackages[0]
                                                            .premiumAmount
                                                    ).toFixed(2);
                                                }
                                            } else if (
                                                product.premium_amount &&
                                                (product.errorMessage !==
                                                    'Referral' ||
                                                    product.errorMessage !==
                                                        'Declined' ||
                                                    product.errorMessage !==
                                                        'Decline')
                                            ) {
                                                emailJourneyPayload[
                                                    item
                                                ] = Number.parseFloat(
                                                    product.premium_amount
                                                        .Premium
                                                ).toFixed(2);
                                            }
                                        }
                                        break;
                                    case 'qid_mn' + seqOfProduct: // monthly premium
                                        if (product.quote_number) {
                                            if (
                                                product.productSourceSystem !==
                                                    'SCI' &&
                                                (product.errorMessage !==
                                                    'Referral' ||
                                                    product.errorMessage !==
                                                        'Declined' ||
                                                    product.errorMessage !==
                                                        'Decline')
                                            ) {
                                                if (product.selectedpackage) {
                                                    emailJourneyPayload[
                                                        item
                                                    ] = (
                                                        Number.parseFloat(
                                                            product
                                                                .selectedpackage
                                                                .premiumAmount
                                                        ) / 12
                                                    ).toFixed(2);
                                                } else {
                                                    emailJourneyPayload[
                                                        item
                                                    ] = Number.parseFloat(
                                                        (
                                                            product
                                                                .productPackages[0]
                                                                .premiumAmount /
                                                            12
                                                        ).toString()
                                                    ).toFixed(2);
                                                }
                                            } else if (
                                                product.premium_amount &&
                                                (product.errorMessage !==
                                                    'Referral' ||
                                                    product.errorMessage !==
                                                        'Declined' ||
                                                    product.errorMessage !==
                                                        'Decline')
                                            ) {
                                                emailJourneyPayload[
                                                    item
                                                ] = Number.parseFloat(
                                                    (
                                                        product.premium_amount
                                                            .Premium / 12
                                                    ).toString()
                                                ).toFixed(2);
                                            }
                                        }
                                        break;
                                    case 'qid_prodSource' + seqOfProduct:
                                        if (product.quote_number) {
                                            if (
                                                product.productId === 'WORKCOMP'
                                            ) {
                                                emailJourneyPayload[item] =
                                                    product.productId;
                                            } else {
                                                emailJourneyPayload[item] =
                                                    product.productSourceSystem;
                                            }
                                        }
                                        break;
                                }
                                // WebUrls
                                if (
                                    emailJourneyPayload[item] !== undefined &&
                                    emailJourneyPayload[item] !== null &&
                                    emailJourneyPayload[item]
                                        .toString()
                                        .includes('qid_quote' + seqOfProduct)
                                ) {
                                    if (product.quote_number) {
                                        emailJourneyPayload[
                                            item
                                        ] = emailJourneyPayload[item]
                                            .toString()
                                            .replace(
                                                'qid_quote' + seqOfProduct,
                                                product.quote_number
                                            );
                                        emailJourneyPayload[
                                            item
                                        ] = emailJourneyPayload[item]
                                            .toString()
                                            .replace(
                                                'qid_product' + seqOfProduct,
                                                product.name
                                                    .toLowerCase()
                                                    .replace(/\s/g, '_')
                                            );
                                        emailJourneyPayload[
                                            item
                                        ] = emailJourneyPayload[item]
                                            .toString()
                                            .replace(
                                                'qid_baseUrl',
                                                environment.baseUrl
                                            );
                                    }
                                }
                            }
                        });
                        // After parsing if any qid is found make it empty
                        if (
                            emailJourneyPayload[item] !== undefined &&
                            emailJourneyPayload[item] !== null &&
                            emailJourneyPayload[item].toString().includes('qid')
                        ) {
                            emailJourneyPayload[item] = '';
                        }
                    }
                } else if (typeof emailJourneyPayload[item] === 'object') {
                    if (Array.isArray(emailJourneyPayload[item])) {
                        for (
                            let j = 0;
                            j < emailJourneyPayload[item].length;
                            j++
                        ) {
                            this.buildEmailJourneyQuoteRequest(
                                emailJourneyPayload[item][j],
                                simpleQuestionsObj,
                                products,
                                emailId,
                                journeyStatus,
                                partnerId,
                                businessInfo,
                                partnerInfo,
                                reviewPageInfo,
                                agentInfo
                            );
                        }
                    } else {
                        if (
                            Object.keys(emailJourneyPayload[item]).length !== 0
                        ) {
                            this.buildEmailJourneyQuoteRequest(
                                emailJourneyPayload[item],
                                simpleQuestionsObj,
                                products,
                                emailId,
                                journeyStatus,
                                partnerId,
                                businessInfo,
                                partnerInfo,
                                reviewPageInfo,
                                agentInfo
                            );
                        }
                    }
                }
            }
        }
        return emailJourneyPayload;
    }

    public buildSaveEmailReq(reqFormat, email, partnerId) {
        reqFormat[0].data[0].values.ContactEmail = email;
        reqFormat[0].data[0].values.PartnerId = partnerId;
        reqFormat[0].data[0].values.Quote1 = 'dsfhwuyfaosjx';

        return reqFormat;
    }

    public buildSaveQuoteReq(
        reqFormat,
        email,
        partnerId,
        insurance,
        revenue,
        zipcode,
        business,
        partnerInfo
    ) {
        reqFormat[0].data[0].values.ContactEmail = email;
        reqFormat[0].data[0].values.PartnerId = partnerId;
        reqFormat[0].data[0].values.producerCode = getContractNumber(
            partnerId,
            zipcode && zipcode.length ? zipcode[0].StateProvince : ''
        );
        reqFormat[0].data[0].values.partnerCode =
            !isNil(partnerInfo) &&
            !isEmpty(partnerInfo) &&
            partnerInfo?.partnercode
                ? partnerInfo.partnercode
                : '';
        reqFormat[0].data[0].values.partnerSource =
            !isNil(partnerInfo) &&
            !isEmpty(partnerInfo) &&
            partnerInfo?.utm_source
                ? partnerInfo.utm_source
                : '';
        reqFormat[0].data[0].values.partnerName =
            !isNil(partnerInfo) &&
            !isEmpty(partnerInfo) &&
            partnerInfo?.utm_source
                ? partnerInfo.utm_source
                : '';

        reqFormat[0].businessInformation.haveInsurance =
            insurance && insurance.toString().toLowerCase() === 'yes'
                ? true
                : false;
        reqFormat[0].businessInformation.revenue = revenue ? revenue : '';
        reqFormat[0].businessInformation.zipcode =
            zipcode && zipcode.length ? zipcode[0].PostalCode : '';
        reqFormat[0].businessInformation.businessType = business
            ? business.name
            : '';
        reqFormat[0].businessInformation.digitalCode = business
            ? business.digitalClassCode
            : '';
        reqFormat[0].businessInformation.wsgClassid = business
            ? business.wsgClassId
            : '';
        return reqFormat;
    }
}
