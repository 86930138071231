import {
    CrossSellProductsActionTypes,
    CrossSellProductsActionUnion,
} from '../actions/cross-sell-products.actions';

export let CrossSellProductsData: any;

export function CrossSellProductsReducer(
    CrossSellProductsInfo = CrossSellProductsData,
    action: CrossSellProductsActionUnion
) {
    switch (action.type) {
        // Flag Setter
        case CrossSellProductsActionTypes.CrossSellProducts: {
            CrossSellProductsInfo = action.payload;
            return CrossSellProductsInfo;
        }
        default: {
            return CrossSellProductsInfo;
        }
    }
}
