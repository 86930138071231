import { Component, OnInit, ElementRef, Input } from '@angular/core';
import { CommonCommunicationService } from '../../../services/common-communication.service';
import { ApplicationMessagesService } from '../../../services/application-messages.service';
import { FormControl, Validators, FormGroup } from '@angular/forms';
import { AnalyticsService } from '@crux/services';
import { Router } from '@angular/router';
import { AffinityPartnerFlags } from '../../../model/affinity-partner';
import { isEmpty, isNil } from 'lodash';

/**
 * ! NGRX store imports
 */
import { Store, select } from '@ngrx/store';
import { Classification } from '../../../model/classification';
import { ZipcodeModel } from '../../../model/zipcode';
import { Product } from '../../../model/product';

/**
 * ! Partner Customization
 */
import { PartnerCustomization } from '../../../partner-customization/partner-customization.helper';
import { DictionaryService } from '../../../services/dictionary.service';

@Component({
    selector: 'app-floating-left-column',
    templateUrl: './floating-left-column.component.html',
    styleUrls: ['./floating-left-column.component.scss'],
})
export class FloatingLeftColumnComponent implements OnInit {
    errorMessages: any;
    emailForm: FormGroup;
    email: FormControl;
    emailSaved: boolean = false;
    completionPercent: number;
    displayEmailField: boolean = false;
    emailFieldValidator: FormControl;
    styleObj: any;
    @Input('post-quote') postQuote: string = '';
    @Input() isRetrieveQuote: boolean = false;
    public totalPremium: number;
    public labelDictionary: any;
    businessLocation: any;
    selectedClass: Classification;
    revenue: number;
    products: Product[];
    AffinityPartnerFlags: AffinityPartnerFlags;
    title: any;
    description: any;
    isFccsUser: boolean;

    constructor(
        private dictionaryService: DictionaryService,
        public ccs: CommonCommunicationService,
        public applicationMessagesService: ApplicationMessagesService,
        public elementRef: ElementRef,
        private _router: Router,
        private _store: Store<{
            totalPremium: number;
            classification: Classification;
            zipcode: ZipcodeModel;
            revenue: number;
            products: Product[];
            AffinityPartnerFlags: AffinityPartnerFlags;
        }>,
        private _partnerCustomization: PartnerCustomization,
        private _analytics: AnalyticsService
    ) {
        this._store.pipe(select('totalPremium')).subscribe((totalPremium) => {
            this.totalPremium = totalPremium;
        });
        this._store.pipe(select('classification')).subscribe((classData) => {
            this.selectedClass = classData;
        });
        this._store.pipe(select('zipcode')).subscribe((zipdata) => {
            this.businessLocation = zipdata[0];
        });
        this._store.pipe(select('revenue')).subscribe((revenue) => {
            this.revenue = revenue;
        });
        this._store
            .pipe(select('AffinityPartnerFlags'))
            .subscribe((AffinityPartnerFlags) => {
                if (AffinityPartnerFlags && AffinityPartnerFlags.partnercode) {
                    if (
                        AffinityPartnerFlags.partnercode &&
                        !isNil(AffinityPartnerFlags.partnercode) &&
                        !isEmpty(AffinityPartnerFlags.partnercode)
                    ) {
                        this.AffinityPartnerFlags = AffinityPartnerFlags;
                        if (this.AffinityPartnerFlags.partnercode === 'FCCS') {
                            this.isFccsUser = true;
                        }
                    }
                }
            });
    }

    ngOnInit() {
        if (
            this.AffinityPartnerFlags &&
            this.AffinityPartnerFlags.partnercode &&
            !isNil(this.AffinityPartnerFlags.partnercode) &&
            !isEmpty(this.AffinityPartnerFlags.partnercode) &&
            this.AffinityPartnerFlags.partnercode === 'CISA'
        ) {
            this.title = 'Your success is our success';
            this.description =
                'We offer the tools you need to help your clients thrive, including top-rated insurance products, cutting-edge resources, and world-class operational support.';
        } else {
            this.title = 'Working in your world';
            this.description =
                "Whether you're starting, growing, or simply maintaining a business, we have a range of products and services that let you stay focused on the challenge at hand.";
        }
    }
}
