import { Action } from '@ngrx/store';

export enum CrossSellProductsActionTypes {
    CrossSellProducts = '[ get cross sell products ] Get cross sell products from blob storage ',
}

export class SetCrossSellProductsAction implements Action {
    readonly type = CrossSellProductsActionTypes.CrossSellProducts;

    constructor(public payload: any) {}
}

export type CrossSellProductsActionUnion = SetCrossSellProductsAction;
