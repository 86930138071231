import { throwError as observableThrowError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { CruxHttpClientService } from '@crux/services';
import { format, addYears } from 'date-fns';
import { find, isEmpty, isNil, isNull } from 'lodash';
import { CommonCommunicationService } from './common-communication.service';
import { UrlSegment } from '@angular/router';
import { AffinityPartnerFlags } from '../model/affinity-partner';
import { Store, select } from '@ngrx/store';
import { AgentInformation } from '../model/agent-info';
import { HttpHeaders } from '@angular/common/http';
import defaultCyberUWQForSentinelone from '../../assets/json/sentinelone-default-cyber-uwq.json';
import defaultCyberUWQ from '../../assets/json/default-cyber-uwq.json';

const affinityPartnerFlags = new AffinityPartnerFlags();
let agentInfo = new AgentInformation();
let producerCodesContent: any;
@Injectable()
export class SCIRateService {
    constructor(
        private _store: Store<{
            AffinityPartnerFlags: AffinityPartnerFlags;
            agentInfoSaved: AgentInformation;
            producerCodesInfo: any;
        }>,
        private http: CruxHttpClientService,
        private ccs: CommonCommunicationService
    ) {
        this._store.pipe(select('agentInfoSaved')).subscribe((res) => {
            if (
                res &&
                res.agencyName &&
                !isNil(res.agencyName) &&
                !isEmpty(res.agencyName)
            ) {
                agentInfo = res;
            }
        });
        this._store
            .pipe(select('AffinityPartnerFlags'))
            .subscribe((AffinityPartnerFlags) => {
                if (AffinityPartnerFlags && AffinityPartnerFlags.partnercode) {
                    affinityPartnerFlags.partnercode =
                        AffinityPartnerFlags.partnercode;
                    affinityPartnerFlags.utm_source =
                        AffinityPartnerFlags.utm_source;
                }
            });
        this._store
            .pipe(select('producerCodesInfo'))
            .subscribe((producerCodesInfo) => {
                if (!isNil(producerCodesInfo) && !isEmpty(producerCodesInfo)) {
                    producerCodesContent = producerCodesInfo;
                }
            });
    }

    updateCoverageRate(payload) {
        const url = environment.baseUrl + environment.uri.scirateupdate;
        return this.http.post(url, { body: payload }).pipe(
            catchError((error: Response) => {
                return observableThrowError(error);
            })
        );
    }

    cyberQuoteRequest(payload, eligibilityQuestions, uwQuestions) {
        let quoteurl = environment.backend + environment.uri.scicyberQuote;
        //quoteurl = quoteurl.replace('#PARTNER#', this.ccs.PartnerId);
        const newPaload = buildCyberQuoteRequestBody(
            payload,
            eligibilityQuestions,
            uwQuestions
        );
        if (
            this.ccs.backendEnvVariable &&
            !isNil(this.ccs.backendEnvVariable) &&
            !isEmpty(this.ccs.backendEnvVariable)
        ) {
            const headers = {};
            headers['env'] = this.ccs.backendEnvVariable;
            return this.http
                .post(quoteurl, {
                    body: newPaload,
                    headers: new HttpHeaders(headers),
                })
                .pipe(
                    catchError((error: Response) => {
                        return observableThrowError(error);
                    })
                );
        } else {
            return this.http.post(quoteurl, { body: newPaload }).pipe(
                catchError((error: Response) => {
                    return observableThrowError(error);
                })
            );
        }
    }

    sciQuoteRequest(payload, eligibilityQuestions, uwQuestions) {
        let quoteurl = environment.backend + environment.uri.sciQuote;
        quoteurl = quoteurl.replace('#PARTNER#', this.ccs.PartnerId);
        const newPaload = buildSciQuoteRequestBody(
            payload,
            eligibilityQuestions,
            uwQuestions
        );
        return this.http.post(quoteurl, { body: newPaload }).pipe(
            catchError((error: Response) => {
                return observableThrowError(error);
            })
        );
    }

    sciUpdateQuoteRequest(
        payload,
        eligibilityQuestions,
        uwQuestions,
        formValues,
        quoteNumber,
        product
    ) {
        let updateQuoteurl =
            environment.backend + environment.uri.sciQuoteUpdate;
        updateQuoteurl = updateQuoteurl.replace(
            '#PARTNER#',
            this.ccs.PartnerId
        );
        const tempPaload = buildSciQuoteRequestBody(
            payload,
            eligibilityQuestions,
            uwQuestions
        );
        const newPaload = buildUpdateQuoteRequestBody(
            tempPaload,
            formValues,
            quoteNumber,
            product
        );
        this.ccs.toberemovedUpdateRateRequest = newPaload;
        return this.http.post(updateQuoteurl, { body: newPaload }).pipe(
            catchError((error: Response) => {
                return observableThrowError(error);
            })
        );
    }

    cyberUpdateQuoteRequest(
        payload,
        eligibilityQuestions,
        uwQuestions,
        formValues,
        quoteNumber,
        product
    ) {
        let updateQuoteurl =
            environment.backend + environment.uri.cyberQuoteUpdate;
        //updateQuoteurl = updateQuoteurl.replace('#PARTNER#',this.ccs.PartnerId);
        const tempPaload = buildCyberQuoteRequestBody(
            payload,
            eligibilityQuestions,
            uwQuestions
        );
        const newPaload = buildCyberUpdateQuoteRequestBody(
            tempPaload,
            formValues,
            quoteNumber,
            product,
            payload
        );
        this.ccs.toberemovedUpdateRateRequest = newPaload;
        if (
            this.ccs.backendEnvVariable &&
            !isNil(this.ccs.backendEnvVariable) &&
            !isEmpty(this.ccs.backendEnvVariable)
        ) {
            const headers = {};
            headers['env'] = this.ccs.backendEnvVariable;
            return this.http
                .post(updateQuoteurl, {
                    body: newPaload,
                    headers: new HttpHeaders(headers),
                })
                .pipe(
                    catchError((error: Response) => {
                        return observableThrowError(error);
                    })
                );
        } else {
            return this.http.post(updateQuoteurl, { body: newPaload }).pipe(
                catchError((error: Response) => {
                    return observableThrowError(error);
                })
            );
        }
    }
}

export const buildSciQuoteRequestBody = (
    data,
    eligibilityQuestions,
    uwQuestions,
    inventory?
) => {
    const sciQuestionRequestBody = {};
    // Temp variables (need to get them from questions)

    // let umbrellaSelected = true;
    let umbrellaSelected = false;
    if (data.allSelectedProductsProductIds.includes('UMBRELLA')) {
        umbrellaSelected = true;
    }

    let bopSelected = false;
    const productsArray = data.allSelectedProductsProductIds.split(',');
    if (productsArray.indexOf('BOP') !== -1) {
        bopSelected = true;
    }
    const product = data.product.productId;

    let effectiveDt = '';
    let expirationDt = '';

    let commercialName = '';
    let legalEntityCd = '';
    let operationsDesc = '';
    const id = 'LOC1';
    const addrTypeCd = 'Primary';
    let addr1 = '';
    const addr2 = '';
    let city = '';
    let state = '';
    let zip = '';

    let sqft = '';
    let payroll = '';

    let businessStartYear = '';
    let annualRevenue: string;
    // if (data.revenue) {
    annualRevenue = data.revenue.toString();
    // }
    let numEmployees = '';

    let unit = '';
    let eachMember = '';
    let avgNumOfAnimalsPerDay = '';
    let totalCost = '';
    let eachKennel = '';
    let eachShow = '';
    let occurenceLimit = '';
    let aggregateLimit = '';
    let productsAggrgateLimit = '';
    let medicalExpanseLimit = '';
    let BPPLimit = '';

    const setUniversalQuestionAnswers = () => {
        data.questions.forEach((questionGroup) => {
            questionGroup.questions.forEach((question) => {
                if (question.question_id === '1') {
                    commercialName = question.value;
                }
                if (question.question_id === '21') {
                    legalEntityCd = question.value.SCI;
                }
                if (question.question_id === '2') {
                    businessStartYear = question.value;
                }
                if (question.question_id === '3') {
                    annualRevenue = question.value;
                }
                if (question.question_id === '4') {
                    addr1 = question.value;
                }
                if (question.question_id === '5') {
                    city = question.value;
                }
                if (question.question_id === '6') {
                    state = question.value;
                }
                if (question.question_id === '7') {
                    zip = question.value;
                }
                if (question.question_id === '15') {
                    effectiveDt = convertDate(question.value, 'start');
                    if (
                        data.partnerId ===
                            CommonCommunicationService.PARTNER_BOLDPENGUIN ||
                        data.partnerId ===
                            CommonCommunicationService.PARTNER_BRIZA
                    ) {
                        expirationDt =
                            data.product &&
                            !isEmpty(data.product.expiration_date)
                                ? data.product.expiration_date
                                : convertDate(question.value, 'end');
                    } else {
                        expirationDt = convertDate(question.value, 'end');
                    }
                }
                if (question.question_id === '10') {
                    sqft = question.value;
                }
                if (question.question_id === '9') {
                    numEmployees = question.value;
                }
                if (question.question_id === '8') {
                    payroll = question.value;
                }
                if (question.question_id === '100') {
                    unit = question.value;
                }
                if (question.question_id === '101') {
                    eachMember = question.value;
                }
                if (question.question_id === '102') {
                    avgNumOfAnimalsPerDay = question.value;
                }
                if (question.question_id === '103') {
                    totalCost = question.value;
                }
                if (question.question_id === '104') {
                    eachKennel = question.value;
                }
                if (question.question_id === '105') {
                    eachShow = question.value;
                }
            });
        });
    };

    const setUWquetions = () => {
        const NewQuestions = [];
        if (uwQuestions.length > 0) {
            uwQuestions.forEach((que) => {
                const questiontype: { [k: string]: any } = {};
                questiontype.Code = que.question_id;
                questiontype.Answer = que.value;
                questiontype.answertype = 'YesNo';
                NewQuestions.push(questiontype);
            });
        }
        const ClassQuestions = [
            {
                LocRef: 'LOC1',
                Questions: NewQuestions,
            },
        ];
        return ClassQuestions;
    };

    const setLiabilityLimits = () => {
        data.product.coverageAndLimits.forEach((coverage) => {
            if (coverage.coverageCd === 'LBMED') {
                occurenceLimit = coverage.defaultValue;
                if (coverage.displayValues) {
                    coverage.displayValues.forEach((limit) => {
                        if (limit.limitAppliesToCd === 'Aggregate') {
                            aggregateLimit = limit.selectedValue;
                        } else if (
                            limit.limitAppliesToCd ===
                            'ProductsCompletedAggregate'
                        ) {
                            productsAggrgateLimit = limit.selectedValue;
                        } else if (limit.limitAppliesToCd === 'PerPerson') {
                            medicalExpanseLimit = limit.selectedValue;
                        }
                    });
                }
            } else if (coverage.coverageCd === 'BPP') {
                BPPLimit = coverage.defaultValue;
            }
        });
    };

    const convertDate = (date, type) => {
        let convertedDate;
        if (type === 'start') {
            convertedDate = format(Date.parse(date), 'yyyy-MM-dd');
        }
        if (type === 'end') {
            convertedDate = format(addYears(Date.parse(date), 1), 'yyyy-MM-dd');
        }
        return convertedDate;
    };

    const commlSublocation = {
        LocRef: 'LOC1',
        SubLocRef: 'BLD1',
        AreaOccupied: '100',
        BldgProtection: {
            NumStories: '2',
            BldgArea: '300',
            Sprinkler: '1',
            YearBuilt: '1980-08-13',
        },
    };
    const liabilityInfo = {};
    let propertyInfo = [];

    const ebayLiabilityInfo = {
        CommlCoverage: [
            {
                CoverageCd: 'LBMED',
                LimitAppliesToCd: 'PerOcc',
                Limit: '300000',
            },
            {
                CoverageCd: 'LBMED',
                LimitAppliesToCd: 'Aggregate',
                Limit: '600000',
            },
            {
                CoverageCd: 'LBMED',
                LimitAppliesToCd: 'PerPerson',
                Limit: '5000',
            },
            {
                CoverageCd: 'LBMED',
                LimitAppliesToCd: 'ProductsCompletedAggregate',
                Limit: '600000',
            },
        ],
    };

    const umbrellaQuoteRateInputs = {
        CommlUmbrellaPolicyInqRq: {
            CommlUmbrellaLineBusiness: {
                CommlCoverage: {
                    Limit: {
                        UmbrellaLimit: '1000000',
                    },
                },
            },
        },
    };

    const buildLiabilityInfo = () => {
        // Since we only allow one location, LOC1 is the only choice we have

        liabilityInfo['CommlCoverage'] = [
            {
                CoverageCd: 'LBMED',
                LimitAppliesToCd: 'PerOcc',
                Limit: occurenceLimit ? occurenceLimit : '1000000',
            },
            {
                CoverageCd: 'LBMED',
                LimitAppliesToCd: 'Aggregate',
                Limit: aggregateLimit ? aggregateLimit : '2000000',
            },
            {
                CoverageCd: 'LBMED',
                LimitAppliesToCd: 'PerPerson',
                Limit: medicalExpanseLimit ? medicalExpanseLimit : '5000',
            },
            {
                CoverageCd: 'LBMED',
                LimitAppliesToCd: 'ProductsCompletedAggregate',
                Limit: productsAggrgateLimit
                    ? productsAggrgateLimit
                    : '2000000',
            },
        ];

        liabilityInfo['LocRef'] = 'LOC1';
        liabilityInfo['ClassCd'] = data.classData.digitalClassCode;
        liabilityInfo['PremiumBasisCd'] = data.classData.premiumBasis;
        switch (data.classData.premiumBasis) {
            case 'Area':
            case 'AREA':
                liabilityInfo['Exposure'] = sqft;
                break;
            case 'GRSALES':
            case 'SALES':
                liabilityInfo['Exposure'] = annualRevenue;
                break;
            case 'PAYRL':
            case 'PAY':
                liabilityInfo['Exposure'] = payroll;
                break;
            case 'Unit':
            case 'UNITS':
                liabilityInfo['Exposure'] = unit;
                break;
            case 'Member':
            case 'MEMBER':
                liabilityInfo['Exposure'] = eachMember;
                break;
            case 'Animal':
            case 'ANIMALS':
                liabilityInfo['Exposure'] = avgNumOfAnimalsPerDay;
                break;
            case 'TotCost':
            case 'COST':
                liabilityInfo['Exposure'] = totalCost;
                break;
            case 'Kennel':
            case 'KENNEL':
                liabilityInfo['Exposure'] = eachKennel;
                break;
            case 'Show':
            case 'SHOWS':
                liabilityInfo['Exposure'] = eachShow;
                break;
        }
        propertyInfo = [
            {
                LocRef: 'LOC1',
                SubLocRef: 'BLD1',
                CommlCoverage: [
                    {
                        CoverageCd: 'BPP',
                        Limit: BPPLimit ? BPPLimit : '50000',
                    },
                ],
            },
        ];
    };

    setUniversalQuestionAnswers();
    setLiabilityLimits();
    buildLiabilityInfo();

    operationsDesc = data.classData.description;

    sciQuestionRequestBody['DigitalQuoteRateRequest'] = {};
    sciQuestionRequestBody['DigitalQuoteRateRequest'][
        'BOPQuoteRateInputs'
    ] = {};
    sciQuestionRequestBody['DigitalQuoteRateRequest']['BOPQuoteRateInputs'][
        'ProducerInfo'
    ] = {};
    const currentContractNumber = getContractNumber(data.partnerId, data.state);
    sciQuestionRequestBody['DigitalQuoteRateRequest']['BOPQuoteRateInputs'][
        'ProducerInfo'
    ]['ContractNumber'] = currentContractNumber;
    sciQuestionRequestBody['DigitalQuoteRateRequest']['BOPQuoteRateInputs'][
        'Product'
    ] = bopSelected ? 'BOP' : 'GL';
    sciQuestionRequestBody['DigitalQuoteRateRequest']['BOPQuoteRateInputs'][
        'ContractTerm'
    ] = {};
    sciQuestionRequestBody['DigitalQuoteRateRequest']['BOPQuoteRateInputs'][
        'ContractTerm'
    ]['EffectiveDt'] = effectiveDt;
    sciQuestionRequestBody['DigitalQuoteRateRequest']['BOPQuoteRateInputs'][
        'ContractTerm'
    ]['ExpirationDt'] = expirationDt;
    sciQuestionRequestBody['DigitalQuoteRateRequest']['BOPQuoteRateInputs'][
        'InsuredOrPrincipal'
    ] = {};
    sciQuestionRequestBody['DigitalQuoteRateRequest']['BOPQuoteRateInputs'][
        'InsuredOrPrincipal'
    ]['CommercialName'] = commercialName;
    sciQuestionRequestBody['DigitalQuoteRateRequest']['BOPQuoteRateInputs'][
        'InsuredOrPrincipal'
    ]['LegalEntityCd'] = legalEntityCd;

    sciQuestionRequestBody['DigitalQuoteRateRequest']['BOPQuoteRateInputs'][
        'InsuredOrPrincipal'
    ]['OperationsDesc'] = operationsDesc;
    sciQuestionRequestBody['DigitalQuoteRateRequest']['BOPQuoteRateInputs'][
        'InsuredOrPrincipal'
    ]['Addr'] = [{}];

    // TODO: Handle address array push
    sciQuestionRequestBody['DigitalQuoteRateRequest']['BOPQuoteRateInputs'][
        'InsuredOrPrincipal'
    ]['Addr'][0]['Id'] = id;
    sciQuestionRequestBody['DigitalQuoteRateRequest']['BOPQuoteRateInputs'][
        'InsuredOrPrincipal'
    ]['Addr'][0]['AddrTypeCd'] = addrTypeCd;
    sciQuestionRequestBody['DigitalQuoteRateRequest']['BOPQuoteRateInputs'][
        'InsuredOrPrincipal'
    ]['Addr'][0]['Addr1'] = addr1;
    sciQuestionRequestBody['DigitalQuoteRateRequest']['BOPQuoteRateInputs'][
        'InsuredOrPrincipal'
    ]['Addr'][0]['Addr2'] = addr2;
    sciQuestionRequestBody['DigitalQuoteRateRequest']['BOPQuoteRateInputs'][
        'InsuredOrPrincipal'
    ]['Addr'][0]['City'] = city;
    sciQuestionRequestBody['DigitalQuoteRateRequest']['BOPQuoteRateInputs'][
        'InsuredOrPrincipal'
    ]['Addr'][0]['State'] = state;
    sciQuestionRequestBody['DigitalQuoteRateRequest']['BOPQuoteRateInputs'][
        'InsuredOrPrincipal'
    ]['Addr'][0]['Zip'] = zip;
    sciQuestionRequestBody['DigitalQuoteRateRequest']['BOPQuoteRateInputs'][
        'InsuredOrPrincipal'
    ]['BusinessInfo'] = {};
    sciQuestionRequestBody['DigitalQuoteRateRequest']['BOPQuoteRateInputs'][
        'InsuredOrPrincipal'
    ]['BusinessInfo']['BusinessStartYear'] = businessStartYear;
    sciQuestionRequestBody['DigitalQuoteRateRequest']['BOPQuoteRateInputs'][
        'InsuredOrPrincipal'
    ]['BusinessInfo']['AnnualRevenue'] = annualRevenue;
    sciQuestionRequestBody['DigitalQuoteRateRequest']['BOPQuoteRateInputs'][
        'InsuredOrPrincipal'
    ]['BusinessInfo']['NumEmployees'] = numEmployees;

    sciQuestionRequestBody['DigitalQuoteRateRequest']['BOPQuoteRateInputs'][
        'ClassQuestions'
    ] = setUWquetions();
    sciQuestionRequestBody['DigitalQuoteRateRequest']['BOPQuoteRateInputs'][
        'CommlSublocation'
    ] = [];
    sciQuestionRequestBody['DigitalQuoteRateRequest']['BOPQuoteRateInputs'][
        'CommlSublocation'
    ][0] = commlSublocation;
    sciQuestionRequestBody['DigitalQuoteRateRequest']['BOPQuoteRateInputs'][
        'LiabilityInfo'
    ] = [];

    // TODO: Get liability info from custom packages/business segment object

    sciQuestionRequestBody['DigitalQuoteRateRequest']['BOPQuoteRateInputs'][
        'LiabilityInfo'
    ][0] = liabilityInfo;

    // TODO: Handle PolicyLvlQuestions array push
    if (umbrellaSelected) {
        sciQuestionRequestBody['DigitalQuoteRateRequest'][
            'UmbrellaQuoteRateInputs'
        ] = umbrellaQuoteRateInputs;
    }
    if (bopSelected) {
        sciQuestionRequestBody['DigitalQuoteRateRequest']['BOPQuoteRateInputs'][
            'PropertyInfo'
        ] = inventory ? getEbayBpp(inventory) : propertyInfo;
    }
    if (
        affinityPartnerFlags &&
        affinityPartnerFlags.partnercode &&
        !isNil(affinityPartnerFlags.partnercode) &&
        !isEmpty(affinityPartnerFlags.partnercode)
    ) {
        sciQuestionRequestBody['DigitalQuoteRateRequest'][
            'CustomProperties'
        ] = {};
        sciQuestionRequestBody['DigitalQuoteRateRequest']['CustomProperties'][
            'subPartnerName'
        ] = affinityPartnerFlags.utm_source;
    }
    return sciQuestionRequestBody;
};

export const buildCyberQuoteRequestBody = (
    data,
    eligibilityQuestions,
    uwQuestions,
    inventory?
) => {
    let effectiveDt = '';
    let expirationDt = '';

    let commercialName = '';
    let legalEntityCd = '';
    let legalEntityExpl = '';
    let addr1 = '';
    let city = '';
    let state = '';
    let zip = '';
    let businessStartYear = '';
    let annualRevenue: string;
    annualRevenue = data.revenue.toString();
    let numEmployees = '';
    let nonProfit = '';
    let numberOfProtectedRecords = '';
    let ownershipType = '';
    let netWorth = '';
    let grossAssets = '';
    let website = data.website;
    let websiteP03 = 'No';

    const cyberQuestionRequestBody = {};
    cyberQuestionRequestBody['digitalQuoteRateRequest'] = {};
    cyberQuestionRequestBody['digitalQuoteRateRequest']['cyberQuoteInqRq'] = {};

    const currentContractNumber = getContractNumber(data.partnerId, data.state);

    cyberQuestionRequestBody['digitalQuoteRateRequest']['cyberQuoteInqRq'][
        'producerInfo'
    ] = {
        contractNumber: currentContractNumber,
        programCode: 'Cyber Specialist',
    };

    const setUniversalQuestionAnswers = () => {
        data.questions.forEach((questionGroup) => {
            questionGroup.questions.forEach((question) => {
                if (question.question_id === '1') {
                    commercialName = question.value;
                }
                if (question.question_id === '21') {
                    legalEntityCd = question.value.CD;
                    if (legalEntityCd === 'Other') {
                        legalEntityExpl = 'Other';
                    }
                }
                if (question.question_id === '23') {
                    nonProfit = question.value;
                }
                if (question.question_id === '24') {
                    numberOfProtectedRecords = question.value;
                }
                if (question.question_id === '25') {
                    ownershipType = question.value;
                }
                if (question.question_id === '26') {
                    netWorth = question.value;
                }
                if (question.question_id === '27') {
                    grossAssets = question.value;
                }
                if (question.question_id === 'P03') {
                    websiteP03 = question.value;
                }
                if (question.question_id === 'P04') {
                    website = question.value;
                }
                if (question.question_id === '2') {
                    businessStartYear = question.value;
                }
                if (question.question_id === '3') {
                    annualRevenue = question.value;
                }
                if (question.question_id === '4') {
                    addr1 = question.value;
                }
                if (question.question_id === '5') {
                    city = question.value;
                }
                if (question.question_id === '6') {
                    state = question.value;
                }
                if (question.question_id === '7') {
                    zip = question.value;
                }
                if (question.question_id === '9') {
                    numEmployees = question.value;
                }
                if (question.question_id === '15') {
                    effectiveDt = convertDate(question.value, 'start');
                    if (
                        data.partnerId ===
                            CommonCommunicationService.PARTNER_BOLDPENGUIN ||
                        data.partnerId ===
                            CommonCommunicationService.PARTNER_BRIZA
                    ) {
                        expirationDt =
                            data.product &&
                            !isEmpty(data.product.expiration_date)
                                ? data.product.expiration_date
                                : convertDate(question.value, 'end');
                    } else {
                        expirationDt = convertDate(question.value, 'end');
                    }
                }
            });
        });
    };

    const setUWquetions = () => {
        let questionsTobeDefaulted = [];
        let removeNA42ChildQues = false;
        const NewQuestions = [];
        const allEligibilityQuestions = [
            {
                code: 'LOSSHISTORY',
                defaultValue: 'No',
            },
        ];
        if (eligibilityQuestions.length > 0) {
            allEligibilityQuestions.forEach((question) => {
                const questiontye: { [k: string]: any } = {};
                const questionExist = find(eligibilityQuestions, (ques) => {
                    return (
                        ques.sci_question_id !== null &&
                        ques.sci_question_id === question.code
                    );
                });
                if (
                    questionExist &&
                    !isNil(questionExist) &&
                    !isEmpty(questionExist)
                ) {
                    questiontye.code = questionExist.sci_question_id;
                    questiontye.answer = questionExist.value;
                    if (
                        NewQuestions &&
                        NewQuestions.length > 0 &&
                        find(NewQuestions, (newquestion) => {
                            return newquestion.code === questiontye.code;
                        })
                    ) {
                        NewQuestions.forEach((que) => {
                            if (que.code === questiontye.code) {
                                que.answer = questiontye.answer;
                            }
                        });
                    } else {
                        NewQuestions.push(questiontye);
                    }
                } else {
                    questiontye.code = question.code;
                    questiontye.answer = question.defaultValue;
                    if (
                        NewQuestions &&
                        NewQuestions.length > 0 &&
                        find(NewQuestions, (newquestion) => {
                            return newquestion.code === questiontye.code;
                        })
                    ) {
                        NewQuestions.forEach((que) => {
                            if (que.code === questiontye.code) {
                                que.answer = questiontye.answer;
                            }
                        });
                    } else {
                        NewQuestions.push(questiontye);
                    }
                }
            });
        }
        if (uwQuestions.length > 0) {
            uwQuestions.forEach((que) => {
                if (
                    !que.subQuestions ||
                    (!isNull(que.value) &&
                        !isEmpty(que.value) &&
                        que.parent_object == null) ||
                    (que.parent_object != null &&
                        que.parent_object.value == que.parent_trigger_value)
                ) {
                    const questiontype: { [k: string]: any } = {};
                    questiontype.code = que.question_id;
                    questiontype.answer = que.value;
                    NewQuestions.push(questiontype);
                    if (
                        que.question_id === 'NA42_New' &&
                        que.value !== 'CompYes'
                    ) {
                        removeNA42ChildQues = true;
                    }
                } else if (que.subQuestions && que.subQuestions.length > 0) {
                    que.subQuestions.forEach((subque) => {
                        if (
                            (!isNull(subque.value) &&
                                !isEmpty(subque.value) &&
                                subque.parent_object == null) ||
                            (subque.parent_object != null &&
                                subque.parent_object.value ==
                                    subque.parent_trigger_value)
                        ) {
                            const subquestiontype: { [m: string]: any } = {};
                            subquestiontype.code = subque.question_id;
                            subquestiontype.answer = subque.value;
                            NewQuestions.push(subquestiontype);
                        }
                    });
                }
            });
            if (
                affinityPartnerFlags &&
                affinityPartnerFlags.partnercode &&
                !isNil(affinityPartnerFlags.partnercode) &&
                !isEmpty(affinityPartnerFlags.partnercode) &&
                affinityPartnerFlags.partnercode === 'sentinelone'
            ) {
                questionsTobeDefaulted = defaultCyberUWQForSentinelone;
            } else {
                questionsTobeDefaulted = defaultCyberUWQ;
            }
            if (questionsTobeDefaulted && questionsTobeDefaulted.length > 0) {
                questionsTobeDefaulted.forEach((que) => {
                    if (
                        que.value &&
                        !isNil(que.value) &&
                        !isEmpty(que.value) &&
                        !que.removeQuestion &&
                        que.includedInApiResponse
                    ) {
                        if (
                            (que.code === 'NA42_2' || que.code === 'NA42_3') &&
                            removeNA42ChildQues
                        ) {
                            return;
                        }
                        const subquestiontype: { [m: string]: any } = {};
                        subquestiontype.code = que.code;
                        subquestiontype.answer = que.value;
                        NewQuestions.push(subquestiontype);
                    }
                });
            }
        }
        return NewQuestions;
    };

    const convertDate = (date, type) => {
        let convertedDate;
        if (type === 'start') {
            convertedDate = format(Date.parse(date), 'yyyy-MM-dd');
        }
        if (type === 'end') {
            convertedDate = format(addYears(Date.parse(date), 1), 'yyyy-MM-dd');
        }
        return convertedDate;
    };

    setUniversalQuestionAnswers();
    if (
        affinityPartnerFlags &&
        affinityPartnerFlags.partnercode &&
        !isNil(affinityPartnerFlags.partnercode) &&
        !isEmpty(affinityPartnerFlags.partnercode)
    ) {
        cyberQuestionRequestBody['digitalQuoteRateRequest'][
            'customProperties'
        ] = {};
        cyberQuestionRequestBody['digitalQuoteRateRequest']['customProperties'][
            'subPartnerName'
        ] = affinityPartnerFlags.utm_source;
        if (affinityPartnerFlags.partnercode === 'CISA') {
            cyberQuestionRequestBody['digitalQuoteRateRequest'][
                'cyberQuoteInqRq'
            ]['producerInfo']['isInsideSales'] = true;
            cyberQuestionRequestBody['digitalQuoteRateRequest'][
                'cyberQuoteInqRq'
            ]['producerInfo']['producerName'] = agentInfo.producerName;
            cyberQuestionRequestBody['digitalQuoteRateRequest'][
                'cyberQuoteInqRq'
            ]['producerInfo']['producerEmail'] = agentInfo.producerEmail;
            cyberQuestionRequestBody['digitalQuoteRateRequest'][
                'customProperties'
            ]['agencyName'] = agentInfo.agencyName;
            cyberQuestionRequestBody['digitalQuoteRateRequest'][
                'customProperties'
            ]['agentEmail'] = agentInfo.producerEmail;
        }
    }

    cyberQuestionRequestBody['digitalQuoteRateRequest']['cyberQuoteInqRq'][
        'contractTerm'
    ] = {
        effectiveDate: effectiveDt,
        expirationDate: expirationDt,
    };
    cyberQuestionRequestBody['digitalQuoteRateRequest']['cyberQuoteInqRq'][
        'insuredOrPrincipal'
    ] = {
        commercialName: commercialName,
        address: [
            {
                id: 'LOC1',
                type: 'PrimaryRisk',
                line1: addr1,
                line2: '',
                city: city,
                state: state,
                postalCode: zip,
            },
        ],
        businessInfo: {
            SubmissionPattern: 'CONVERSATION',
            legalEntityCode: legalEntityCd,
            legalEntityExpl: legalEntityExpl,
            briefDescription: data.classData.description,
            businessStartYear: businessStartYear,
            numberOfEmployees: numEmployees,
            nonProfit: nonProfit,
            priorRevenue: annualRevenue,
            projectedRevenue: annualRevenue,
            domesticRevenuePct: '',
            numberOfProtectedRecords: numberOfProtectedRecords,
            website: website,
            classCode: data.classData.digitalClassCode,
            cyberClassCode: '',
            ownershipType: ownershipType,
            netWorth: netWorth,
            grossAssets: grossAssets,
        },
        lossInfo: [],
        questions: setUWquetions(),
    };

    const setquoteOptions = () => {
        const coverages = [];
        data.product.coverageAndLimits.forEach((coverageLimit) => {
            const coveragetype: { [k: string]: any } = {};

            if (coverageLimit.coverageCd == 'CyberLimit') {
                coveragetype.code = coverageLimit.limitAppliesToCd;
            } else {
                coveragetype.code = coverageLimit.coverageCd;
            }

            coverageLimit.values.forEach((coveraLimit) => {
                if (coveraLimit.subCoverage[0].coverage == 'Policy Limits') {
                    coveragetype.limit =
                        coveraLimit.subCoverage[0].defaultValue;
                }
                if (coveraLimit.subCoverage[0].coverage == 'Coverage Limit') {
                    coveragetype.limit =
                        coveraLimit.subCoverage[0].defaultValue;
                }
                if (coveraLimit.subCoverage[0].coverage == 'Retention') {
                    coveragetype.retention =
                        coveraLimit.subCoverage[0].defaultValue;
                }
                if (coveraLimit.subCoverage[0].coverage == 'Waiting Period') {
                    coveragetype.waitingPeriod =
                        coveraLimit.subCoverage[0].defaultValue;
                }
                // if (
                //     coveraLimit.subCoverage[0].coverage ==
                //     'Cyber Incident Response Fund Side Car'
                // ) {
                //     let cirfsc = '';
                //     if (
                //         coveraLimit.subCoverage[0].defaultValue ==
                //         'Standard Coverage'
                //     ) {
                //         cirfsc = 'Standard';
                //     } else if (
                //         coveraLimit.subCoverage[0].defaultValue ==
                //         'Affected Persons Side-Car'
                //     ) {
                //         cirfsc = 'AffectedPersons';
                //     } else if (
                //         coveraLimit.subCoverage[0].defaultValue ==
                //         'Full CIRF Side-Car'
                //     ) {
                //         cirfsc = 'FullCIRF';
                //     }
                //     coveragetype.incidentRespSidecar = cirfsc;
                // }
                // if (
                //     coveraLimit.subCoverage[0].coverage ==
                //     'Protected Information Coinsurance'
                // ) {
                //     let pic = '';
                //     if (
                //         coveraLimit.subCoverage[0].defaultValue ==
                //         'No, do not include'
                //     ) {
                //         pic = 'No';
                //     } else if (
                //         coveraLimit.subCoverage[0].defaultValue ==
                //         'Yes, include coinsurance'
                //     ) {
                //         pic = 'Yes';
                //     }
                //     coveragetype.inclProtectedInfoCoIns = pic;
                // }
                // if (
                //     coveraLimit.subCoverage[0].coverage ==
                //     'Number Of Affected Persons'
                // ) {
                //     coveragetype.numberOfAffectedPersons =
                //         coveraLimit.subCoverage[0].defaultValue;
                // }
                if (coveraLimit.subCoverage[0].coverage == 'Full Prior Acts') {
                    coveragetype.fullPriorActs =
                        coveraLimit.subCoverage[0].defaultValue;
                }
                if (coveraLimit.subCoverage[0].coverage == 'Retroactive Date') {
                    const currentYear = new Date().getUTCFullYear();
                    if (currentYear - parseInt(businessStartYear, 10) >= 5) {
                        const date = new Date(
                            parseInt(businessStartYear, 10),
                            1,
                            1,
                            0,
                            0,
                            0
                        );
                        date.setUTCMonth(0, 1);
                        date.setUTCHours(0, 0, 0, 0);
                        coveragetype.retroactiveDate = date;
                    } else if (
                        currentYear - parseInt(businessStartYear, 10) <
                        5
                    ) {
                        const year = currentYear - 5;
                        const date = new Date(year, 1, 1, 0, 0, 0);
                        date.setUTCMonth(0, 1);
                        date.setUTCHours(0, 0, 0, 0);
                        coveragetype.retroactiveDate = date;
                    } else {
                        coveragetype.retroactiveDate =
                            coveraLimit.subCoverage[0].defaultValue;
                    }
                }
            });

            coveragetype.exclude = false;

            coverages.push(coveragetype);
        });
        return coverages;
    };

    cyberQuestionRequestBody['digitalQuoteRateRequest']['cyberQuoteInqRq'][
        'lineOfBusiness'
    ] = {
        quoteOptions: [
            {
                optionId: '10',
                coverages: setquoteOptions(),
            },
        ],
    };

    return cyberQuestionRequestBody;
};

export const getContractNumber = (partnerId, state) => {
    // Chubb PAS Producer Codes
    // Literally airlifted from below function.
    // Team Focus or PBN
    let producerCode: string;
    if (!isNil(producerCodesContent) && !isEmpty(producerCodesContent)) {
        producerCodesContent.forEach((partner) => {
            if (
                affinityPartnerFlags.partnercode &&
                !isNil(affinityPartnerFlags.partnercode) &&
                !isEmpty(affinityPartnerFlags.partnercode) &&
                partner.partnerId === affinityPartnerFlags.partnercode
            ) {
                if (partner.stateSpecificCodes.length === 0) {
                    producerCode = partner.defaultCode;
                } else {
                    const stateExist = find(partner.stateSpecificCodes, [
                        'state',
                        state,
                    ]);
                    if (!isNil(stateExist) && !isEmpty(stateExist)) {
                        producerCode = stateExist.code;
                    } else {
                        producerCode = partner.defaultCode;
                    }
                }
            } else if (partner.partnerId === partnerId) {
                if (partner.stateSpecificCodes.length === 0) {
                    producerCode = partner.defaultCode;
                } else {
                    const stateExist = find(partner.stateSpecificCodes, [
                        'state',
                        state,
                    ]);
                    if (!isNil(stateExist) && !isEmpty(stateExist)) {
                        producerCode = stateExist.code;
                    } else {
                        producerCode = partner.defaultCode;
                    }
                }
            }
        });
        return producerCode;
    } else {
        return 'Z07312';
    }
};

export const getPartnerLogo = (partnerId) => {
    // Chubb PAS Producer Codes
    // Literally airlifted from below function.
    // Team Focus or PBN
    let logoURL: string;
    if (!isNil(producerCodesContent) && !isEmpty(producerCodesContent)) {
        producerCodesContent.forEach((partner) => {
            if (
                affinityPartnerFlags.partnercode &&
                !isNil(affinityPartnerFlags.partnercode) &&
                !isEmpty(affinityPartnerFlags.partnercode) &&
                partner.partnerId === affinityPartnerFlags.partnercode
            ) {
                logoURL = partner.emailLogoUrl;
            } else if (partner.partnerId === partnerId) {
                logoURL = partner.emailLogoUrl;
            }
        });
        return logoURL;
    } else {
        return '';
    }
};

export const getEbayBpp = (inventory) => {
    return [
        {
            LocRef: 'LOC1',
            SubLocRef: 'BLD1',
            CommlCoverage: [
                {
                    CoverageCd: 'BPP',
                    Limit: inventory.toString(),
                },
            ],
        },
    ];
};

export const buildCyberUpdateQuoteRequestBody = (
    request,
    formValues,
    quoteNumber,
    product,
    data?
) => {
    let DisCoinsurance = formValues.value.DisCoinsurance;
    let DisLimit = formValues.value.DisLimit;
    let DisRetention = formValues.value.DisRetention;
    let DisWait = formValues.value.DisWait;
    let DisInc = formValues.value.DisInc;
    let DisNoPersons = formValues.value.DisNoPersons;
    let segment = '';
    let businessStartYear = '';
    const updateRequestBody = request;

    updateRequestBody['digitalQuoteRateRequest']['cyberQuoteInqRq'][
        'quoteInfo'
    ] = {
        quoteNumber: quoteNumber,
    };

    data.questions.forEach((questionGroup) => {
        questionGroup.questions.forEach((question) => {
            if (question.question_id === '2') {
                businessStartYear = question.value;
            }
        });
    });

    const setquoteOptions = () => {
        const coverages = [];
        product.coverageAndLimits.forEach((coverageLimit) => {
            const coveragetype: { [k: string]: any } = {};

            if (coverageLimit.coverageCd == 'CyberLimit') {
                coveragetype.code = coverageLimit.limitAppliesToCd;
            } else {
                coveragetype.code = coverageLimit.coverageCd;
            }

            coverageLimit.values.forEach((coveraLimit, index) => {
                if (!coverageLimit.isOptional) {
                    if (
                        coveraLimit.subCoverage[0].coverage == 'Policy Limits'
                    ) {
                        coveragetype.limit = DisLimit;
                    }
                    if (
                        coveraLimit.subCoverage[0].coverage == 'Coverage Limit'
                    ) {
                        coveragetype.limit = DisLimit;
                    }

                    if (coveraLimit.subCoverage[0].coverage == 'Retention') {
                        coveragetype.retention = DisRetention;
                    }

                    if (
                        coveraLimit.subCoverage[0].coverage == 'Waiting Period'
                    ) {
                        coveragetype.waitingPeriod = DisWait;
                    }

                    // if (
                    //     coveraLimit.subCoverage[0].coverage ==
                    //     'Cyber Incident Response Fund Side Car'
                    // ) {
                    //     let cirfsc = '';
                    //     if (DisInc == 'Standard Coverage') {
                    //         cirfsc = 'Standard';
                    //     } else if (DisInc == 'Affected Persons Side-Car') {
                    //         cirfsc = 'AffectedPersons';
                    //     } else if (DisInc == 'Full CIRF Side-Car') {
                    //         cirfsc = 'FullCIRF';
                    //     }
                    //     coveragetype.incidentRespSidecar = cirfsc;
                    // }

                    // if (
                    //     coveraLimit.subCoverage[0].coverage ==
                    //     'Protected Information Coinsurance'
                    // ) {
                    //     let pic = '';
                    //     if (DisCoinsurance == 'No, do not include') {
                    //         pic = 'No';
                    //     } else if (
                    //         DisCoinsurance == 'Yes, include coinsurance'
                    //     ) {
                    //         pic = 'Yes';
                    //     }
                    //     coveragetype.inclProtectedInfoCoIns = pic;
                    // }

                    // if (
                    //     coveraLimit.subCoverage[0].coverage ==
                    //     'Number Of Affected Persons'
                    // ) {
                    //     coveragetype.numberOfAffectedPersons = DisNoPersons;
                    // }

                    if (
                        coveraLimit.subCoverage[0].coverage == 'Full Prior Acts'
                    ) {
                        coveragetype.fullPriorActs =
                            coveraLimit.subCoverage[0].defaultValue;
                    }
                    if (
                        coveraLimit.subCoverage[0].coverage ==
                        'Retroactive Date'
                    ) {
                        coveragetype.retroactiveDate =
                            coveraLimit.subCoverage[0].defaultValue;
                    }
                    coveragetype.exclude = false;
                } else if (coverageLimit.isOptional) {
                    if (
                        coveraLimit.subCoverage[0].coverage == 'Policy Limits'
                    ) {
                        coveragetype.limit =
                            coveraLimit.subCoverage[0].defaultValue;
                    }
                    if (
                        coveraLimit.subCoverage[0].coverage == 'Coverage Limit'
                    ) {
                        coveragetype.limit =
                            coveraLimit.subCoverage[0].defaultValue;
                    }
                    if (coveraLimit.subCoverage[0].coverage == 'Retention') {
                        coveragetype.retention =
                            coveraLimit.subCoverage[0].defaultValue;
                    }
                    if (
                        coveraLimit.subCoverage[0].coverage == 'Waiting Period'
                    ) {
                        coveragetype.waitingPeriod =
                            coveraLimit.subCoverage[0].defaultValue;
                    }
                    // if (
                    //     coveraLimit.subCoverage[0].coverage ==
                    //     'Cyber Incident Response Fund Side Car'
                    // ) {
                    //     let cirfsc = '';
                    //     if (
                    //         coveraLimit.subCoverage[0].defaultValue ==
                    //         'Standard Coverage'
                    //     ) {
                    //         cirfsc = 'Standard';
                    //     } else if (
                    //         coveraLimit.subCoverage[0].defaultValue ==
                    //         'Affected Persons Side-Car'
                    //     ) {
                    //         cirfsc = 'AffectedPersons';
                    //     } else if (
                    //         coveraLimit.subCoverage[0].defaultValue ==
                    //         'Full CIRF Side-Car'
                    //     ) {
                    //         cirfsc = 'FullCIRF';
                    //     }
                    //     coveragetype.incidentRespSidecar = cirfsc;
                    // }
                    // if (
                    //     coveraLimit.subCoverage[0].coverage ==
                    //     'Protected Information Coinsurance'
                    // ) {
                    //     let pic = '';
                    //     if (
                    //         coveraLimit.subCoverage[0].defaultValue ==
                    //         'No, do not include'
                    //     ) {
                    //         pic = 'No';
                    //     } else if (
                    //         coveraLimit.subCoverage[0].defaultValue ==
                    //         'Yes, include coinsurance'
                    //     ) {
                    //         pic = 'Yes';
                    //     }
                    //     coveragetype.inclProtectedInfoCoIns = pic;
                    // }
                    // if (
                    //     coveraLimit.subCoverage[0].coverage ==
                    //     'Number Of Affected Persons'
                    // ) {
                    //     coveragetype.numberOfAffectedPersons =
                    //         coveraLimit.subCoverage[0].defaultValue;
                    // }
                    if (
                        coveraLimit.subCoverage[0].coverage == 'Full Prior Acts'
                    ) {
                        coveragetype.fullPriorActs =
                            coveraLimit.subCoverage[0].defaultValue;
                    }
                    if (
                        coveraLimit.subCoverage[0].coverage ==
                        'Retroactive Date'
                    ) {
                        const currentYear = new Date().getUTCFullYear();
                        if (
                            currentYear - parseInt(businessStartYear, 10) >=
                            5
                        ) {
                            const date = new Date(
                                parseInt(businessStartYear, 10),
                                1,
                                1,
                                0,
                                0,
                                0
                            );
                            date.setUTCMonth(0, 1);
                            date.setUTCHours(0, 0, 0, 0);
                            coveragetype.retroactiveDate = date;
                        } else if (
                            currentYear - parseInt(businessStartYear, 10) <
                            5
                        ) {
                            const year = currentYear - 5;
                            const date = new Date(year, 1, 1, 0, 0, 0);
                            date.setUTCMonth(0, 1);
                            date.setUTCHours(0, 0, 0, 0);
                            coveragetype.retroactiveDate = date;
                        } else {
                            coveragetype.retroactiveDate =
                                coveraLimit.subCoverage[0].defaultValue;
                        }
                    }
                    coveragetype.exclude = !coverageLimit.isChecked;
                }
            });

            coverages.push(coveragetype);
        });
        return coverages;
    };

    if (product.productId == 'CYBER-ERM') {
        segment = 'CyberERM';
    } else if (product.productId == 'CYBER-DIGITECT') {
        segment = 'DigiTech';
    }

    updateRequestBody['digitalQuoteRateRequest']['cyberQuoteInqRq'][
        'lineOfBusiness'
    ] = {
        segment: segment,
        quoteOptions: [
            {
                optionId: '10',
                coverages: setquoteOptions(),
            },
        ],
    };

    return updateRequestBody;
};

export const buildUpdateQuoteRequestBody = (
    request,
    formValues,
    quoteNumber,
    product
) => {
    const updateRequestBody = request;

    if (product.productId === 'UMBRELLA') {
        updateRequestBody.DigitalQuoteRateRequest[
            'UmbrellaQuoteRateInputs'
        ] = JSON.parse(JSON.stringify(umbrellaUpdateRequestProp));
        updateRequestBody.DigitalQuoteRateRequest.UmbrellaQuoteRateInputs.QuoteInfo.QuoteNumber =
            product.quote_number;
        updateRequestBody.DigitalQuoteRateRequest.UmbrellaQuoteRateInputs.CommlUmbrellaPolicyInqRq.CommlUmbrellaLineBusiness.CommlCoverage.Limit.UmbrellaLimit =
            formValues.value.Umbrella;
    }
    if (product.productId === 'BOP' || product.productId === 'GL') {
        updateRequestBody.DigitalQuoteRateRequest.BOPQuoteRateInputs.LiabilityInfo[
            '0'
        ]['CommlCoverage'].forEach((coverage) => {
            if (coverage.LimitAppliesToCd === 'PerOcc') {
                let perOcc: any;
                if (!formValues.value) {
                    perOcc = find(
                        formValues.DigitalQuoteRateResponse.BOPQuoteRateOutputs
                            .LiabilityInfo[0].CommlCoverage,
                        ['LimitAppliesToCd', coverage.LimitAppliesToCd]
                    );
                }
                coverage.Limit = formValues.value
                    ? formValues.value.PerOcc
                    : perOcc.Limit;
            }
            if (coverage.LimitAppliesToCd === 'PerPerson') {
                let perPerson: any;
                if (!formValues.value) {
                    perPerson = find(
                        formValues.DigitalQuoteRateResponse.BOPQuoteRateOutputs
                            .LiabilityInfo[0].CommlCoverage,
                        ['LimitAppliesToCd', coverage.LimitAppliesToCd]
                    );
                }
                coverage.Limit = formValues.value
                    ? formValues.value.PerPerson
                    : perPerson.Limit;
            }
            if (coverage.LimitAppliesToCd === 'ProductsCompletedAggregate') {
                let prodCompletedAgg: any;
                if (!formValues.value) {
                    prodCompletedAgg = find(
                        formValues.DigitalQuoteRateResponse.BOPQuoteRateOutputs
                            .LiabilityInfo[0].CommlCoverage,
                        ['LimitAppliesToCd', coverage.LimitAppliesToCd]
                    );
                }
                coverage.Limit = formValues.value
                    ? formValues.value.ProductsCompletedAggregate
                    : prodCompletedAgg.Limit;
            }
            if (coverage.LimitAppliesToCd === 'Aggregate') {
                let aggregate: any;
                if (!formValues.value) {
                    aggregate = find(
                        formValues.DigitalQuoteRateResponse.BOPQuoteRateOutputs
                            .LiabilityInfo[0].CommlCoverage,
                        ['LimitAppliesToCd', coverage.LimitAppliesToCd]
                    );
                }
                coverage.Limit = formValues.value
                    ? formValues.value.Aggregate
                    : aggregate.Limit;
            }
        });

        if (product.productId === 'BOP') {
            updateRequestBody.DigitalQuoteRateRequest.BOPQuoteRateInputs.PropertyInfo[
                '0'
            ]['CommlCoverage']['0'].Limit = formValues.value
                ? formValues.value.PersonalProperty
                : formValues.DigitalQuoteRateResponse.BOPQuoteRateOutputs
                      .PropertyInfo['0']['CommlCoverage']['0'].Limit;
        }
    }
    // delete newRequest.DigitalQuoteRateResponse;

    updateRequestBody['DigitalQuoteRateRequest']['DoNotRate'] = 'false';
    updateRequestBody['DigitalQuoteRateRequest']['UpdateOptionalCoverages'] =
        'false';
    updateRequestBody['DigitalQuoteRateRequest']['BOPQuoteRateInputs'][
        'QuoteInfo'
    ] = {
        QuoteNumber: quoteNumber,
    };
    // updateRequestBody['DigitalQuoteRateRequest']['BOPQuoteRateInputs']['QuoteInfo']['QuoteNumber'] = quoteNumber;
    return updateRequestBody;
};

const umbrellaUpdateRequestProp = {
    QuoteInfo: {
        QuoteNumber: '',
    },
    CommlUmbrellaPolicyInqRq: {
        CommlUmbrellaLineBusiness: {
            CommlCoverage: {
                Limit: {
                    UmbrellaLimit: '',
                },
            },
        },
    },
};
